import React from "react";
import Helmet from "react-helmet";
import { Container } from "@material-ui/core";
import FinanceVehicleCalculator from "../components/FinanceVehicleCalculate";
import { NavBar } from "../components/NavBar";

function VehicleInstallMentCalculatorPage() {
  return (
    <>
      <Helmet>
        <title>โปรแกรมคำนวณงวดรถออนไลน์ — Calculate Anything</title>
        <meta
          name="description"
          content="ตัวช่วยคำนวณงวดรถออนไลน์ ประกอบการตัดสินใจ"
        />
      </Helmet>
      <Container maxWidth="lg">
        <NavBar />
        <h1>โปรแกรมคำนวณงวดรถออนไลน์</h1>
      </Container>

      <section>
        {typeof window !== `undefined` ? (
          <FinanceVehicleCalculator />
        ) : (
          <>Loading..</>
        )}
      </section>
    </>
  );
}

export default VehicleInstallMentCalculatorPage;
